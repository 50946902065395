<template>
  <div class="app-context expanded">
    <app-header :headerOption="headerOption" ref="appheader"></app-header>
    <div class="context complex">
      <side-menu
        :sideSet="sideSet"
        @call-pop-in-side-password="callPopWithSidePassword"
        @call-pop-in-side-issue="callPopWithSideIssue"
        @draw-done="initAuthInfo"
        @click-list="listReaded"
        ref="sideContext"
      ></side-menu>
      <div class="c-context">
        <directive :directiveInfo="directiveInfo"></directive>
        <div class="join document">
          <div class="info">
            <div class="article">
              <h5>
                단, 아래 항목은 표기된 법률에 따라 특정기간 동안 보관됩니다.
              </h5>
              <p>
                DrRefer가 수집 · 보유한 이용자의 개인(신용)정보는 회원 탈퇴 시
                지체없이 파기함을 원칙으로 합니다.
              </p>
              <p>
                단, 사고 조사, 분쟁 해결, 민원처리, 법률상 의무이행만을 위하여
                일부 개인(신용)정보는 보유 · 이용될 수 있습니다.
              </p>
              <p>
                온라인 거래 관련 개인(신용)정보는 전자 금융 거래법 시행령 제
                12조에서 정하는 기간까지 보유 · 이용됩니다.
              </p>
            </div>
            <div class="article">
              <h5>파기방법</h5>
              <p>개인정보가 기록된 출력물ㆍ서면 등을 파쇄 또는 소각</p>
              <p>
                전자적 파일형태의 개인정보: 복원이 불가능한 방법으로 영구삭제
              </p>
            </div>
          </div>
          <!--
          <div class="document-btn-wrap">
            <button type="button" class="draw" @click="draw()">
              탈퇴
            </button>
          </div>
          -->
          <div class="document-btn-wrap">
            <div class="item iconIn delete" @click="draw()">
              <img src="@/assets/images/menuIcon/draw.png" />
              <p>탈퇴</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import SideMenu from "@/components/sidemenu/SideMenu";
import Directive from "@/components/join/Directive";

export default {
  components: {
    SideMenu,
    Directive,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },

      directiveInfo: {
        title: "회원정보 탈퇴",
        isTitleNewLine: false,
        content:
          "회원탈퇴 처리 후에는 회원님의 개인정보를 복원할 수 없으며,\n 탈퇴 진행 시 해당 아이디는 영구적으로 삭제되어 재가입이 불가합니다.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
    }
  },
  mounted() {
    document.querySelector(".draw").classList.add("active");
  },
  computed: {},
  methods: {
    draw() {
      this.$refs.sideContext.requestwithDraw();
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    initAuthInfo() {
      //console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    async callPopWithSideIssue(subData) {
      //console.log("sub", subData);
      let pop;
      pop = {
        popType: "IssueChild",
        title: "부계정 관리",
        content:
          "발급할 부계정은 <b style='color:#ff0a00'>1" +
          localStorage.getItem("drId") +
          " </b>입니다.\n변경할 부계정의 비밀번호를 입력해주세요.",
        immediatly: true,
        functionValue: subData,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
@import "@/assets/css/sideContext";
.info {
  border: 1px solid #95989a;
  border-radius: 4px;
  padding: 35px 40px;
  background: #f2f2f2;
  margin: 0 0 20px 0;
  .article {
    margin: 30px 0 0 0;
    &:first-of-type {
      margin: 0;
    }
    h5 {
      margin: 0 0 10px 0;
      font-family: Noto Sans KR;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 26px;
      letter-spacing: normal;
      text-align: left;
      color: #146f83;
    }
    p {
      font-family: SUIT;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: normal;
      text-align: left;
      padding: 0 0 0 20px;
      position: relative;
      color: #000000;
      &:after {
        position: absolute;
        left: 0;
      }
      &:nth-of-type(1) {
        &:after {
          content: "1.";
        }
      }
      &:nth-of-type(2) {
        &:after {
          content: "2.";
        }
      }
      &:nth-of-type(3) {
        &:after {
          content: "3.";
        }
      }
    }
  }
}
.document-btn-wrap {
  .item.delete {
    background: #ff7272;
  }
}
</style>
